import React, { useState } from "react";
import { css, Global } from "@emotion/core";
import { StylesProvider, ThemeProvider } from "@material-ui/core/styles";
import theme from "../styles/theme";
import reset from "../styles/reset";

const styling = () => css`
  ${reset}

  @import url("https://fast.fonts.net/lt/1.css?apiType=css&c=5287343a-9c1a-4f5f-ba9d-842b6610e5c0&fontids=1391048,1391060,6148038,6148049");
  @font-face {
    font-family: "Britannic";
    font-weight: bold;
    src: url("/fonts/1391048/337b2537-cf5f-4de9-9c62-320b61d3133c.woff2")
        format("woff2"),
      url("/fonts/1391048/4b847f37-a816-4adc-b840-5873f09dcad7.woff")
        format("woff");
  }
  @font-face {
    font-family: "Britannic";
    src: url("/fonts/1391060/95434bf5-b37d-42d4-b964-f476d982d212.woff2")
        format("woff2"),
      url("/fonts/1391060/e24f9a13-25f4-4006-974d-f2af1b2db250.woff")
        format("woff");
  }
  @font-face {
    font-family: "Cochin";
    src: url("/fonts/6148038/34425e17-82c8-4cc1-82ab-935090b4afae.woff2")
        format("woff2"),
      url("/fonts/6148038/2f9a35d9-b5ca-4f4f-84fa-89802aef2f5b.woff")
        format("woff");
  }
  @font-face {
    font-family: "Cochin";
    font-weight: bold;
    src: url("/fonts/6148049/a5ccd730-f1e6-45b0-988b-8aa9b4612617.woff2")
        format("woff2"),
      url("/fonts/6148049/79e3745c-0fb1-4ea0-963b-5a078924bc52.woff")
        format("woff");
  }

  body {
    background: #faf9f7;
    color: #15243f;
    margin: 0;
    position: relative;
    font-family: "Cochin";
    font-size: 18px;
    line-height: 1.3;
    padding-top: 72px;
    ${theme.breakpoints.sm} {
      padding-top: 50px;
    }
  }

  main {
    overflow: hidden;
  }

  .content-container {
    width: 100%;
    margin: 0 auto;
    max-width: 1260px;
    padding: 0 30px;

    ${theme.breakpoints.sm} {
      padding: 0 20px;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-family: "Britannic";
    font-weight: bold;
  }

  h1 {
    font-size: 30px;
    margin-bottom: 0.666em;
  }

  a,
  a:visited {
    font-weight: bold;
    text-decoration: none;
    color: #ec652f;
    transition: 0.3s;
  }
`;

const Layout = ({ children }) => (
  <>
    <Global styles={styling()} />
    <StylesProvider injectFirst>
      <ThemeProvider theme={theme}>{children}</ThemeProvider>
    </StylesProvider>
  </>
);

Layout.Container = ({ children }) => (
  <div className="content-container">{children}</div>
);

export default Layout;
