const theme = {
  breakpoints: {
    values: {
      sm: 767,
      md: 1024,
    },
    sm: "@media (max-width: 767px)",
  },
};

export default theme;
